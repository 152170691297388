.my-video>video {
  height: 320px;
}

.my-video-live {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 160px;
  height: 120px;
  z-index: 98;
}

.my-video-live>video {
  width: 160px;
  max-height: 120px;
}

@media screen and (max-width: 720px) {
  .my-video {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    overflow: hidden;
  }

  .my-video>video {
    max-height: 260px;
  }

  .my-video-live {
    bottom: 10px;
    right: 10px;
  }
}

@media screen and (max-width: 512px) {
  .my-video-live {
    width: 80px;
    height: 60px;
  }

  .my-video-live>video {
    width: 80px;
    max-height: 60px;
  }
}