.MuiTab-wrapper {
 font-size: 10px!important;
}
.MuiSvgIcon-root {
    width: 20px!important;
    height: 20px!important;
}
.MuiTab-labelIcon {
    min-height: 50px!important;
}
.MuiListItem-secondaryAction {
    padding-left: 0!important;
    border-bottom: 1px solid #ccc;
}
.MuiTypography-colorTextSecondary {
    font-size: 8px!important;
    font-style: italic;
}
.MuiTypography-body1 {
    font-size: 11px!important;
}