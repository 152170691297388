.titleBar {
  display: flex;
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #dadada;
  justify-items: stretch;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.titleBar h3 {
  font-size: 1.5rem;
  font-weight: bold;
}