.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.main-content {
  box-shadow: 0 0 20px 2px #d2d2d2;
  padding: 20px auto;
  border-radius: 5px;
  background-color: #fff;
}

.hero-header {
  box-shadow: 0 0 2px 2px #d2d2d2;
  background-color: #a8bbc1;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 5px;
  margin-top: 8px;
  text-align: center;
}
.hero-header h1{
  font-size: 16px;
}

.item-image,
.item-options {
  border: 1px solid #d2d2d2;
  padding: 4px;
  margin: 16px;
  box-shadow: 0 0 10px 2px #a8bbc1;
  height: 450px;
  border-radius: 5px;
}

.item-image2,
.item-options {
  border: 1px solid #d2d2d2;
  padding: 4px;
  margin: 16px;
  box-shadow: 0 0 10px 2px #a8bbc1;
  height: 450px;
  border-radius: 5px;
}

.timer-comp {
  margin-top: 13%;
  display: flex;
  justify-content: center;
}

.item-image,
.option-loader,
.display-result {
  display: flex;
  justify-content: center;
  align-items: center;
}
.option {
  display: block;
  width: 100%;
  background-color: #a8bbc1;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #d2d2dd;
  box-shadow: 0 0 2px 2px #d2d2d2;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 8px;
  text-align: center
}

.option:hover {
  background-color: #6b929e;
}

.continue-btn-wrapper {
  margin-top: 16px;
  text-align: center;
}

.continue-btn {
  padding: 12px 20px;
  background-color: #6b929e;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 0 2px 2px #d2d2d2;
  cursor: pointer;
}

.continue-btn:hover {
  background-color: #3d6673;
}

#footer {
  margin-top: 20px;
  font-size: 14px;
}

.green {
  background-color: green;
}
.green:hover {
  background-color: green;
}

.red {
  background-color: red;
}

.red:hover {
  background-color: red;
}

.display-result {
  min-height: 260px;
  font-size: 48px;
  flex-wrap: wrap;
}

.display-result p {
  width: 100%;
  text-align: center;
}

.display-result ul {
  font-size: 20px;
}

.win-lose span {
  font-size: 24px;
  font-weight: bolder;
}
.winner-label {
  color: green;
}
.loser-label {
  color: red;
}

@media only screen and (max-width: 400px) {
  .item-options,
  .item-image {
    margin: 16px 0;
  }

  .timer-comp {
    margin: 5% 0;
  }
}