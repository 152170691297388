body,
#root {
  height: auto;
  background: #f5eaea;
}
.progress-bar-container {
  width: 100%;
  height: 20px; /* Adjust the height as needed */
  background-color: #f0f0f0; /* Background color of the progress bar container */
}

.progress-bar {
  height: 100%;
  background-color: #4caf50; /* Color of the progress bar */
}

.bg-red{
  background: linear-gradient(0deg, rgb(168, 9, 10), rgb(211, 50, 50));
}
.bg-yellow{
  background: linear-gradient(0deg, rgb(218, 131, 9), rgb(255, 173, 57));
}
.bg-purple{
  background: linear-gradient(0deg, rgb(85, 6, 120), rgb(119, 9, 168));
}
.bg-dark-red{
  background: linear-gradient(0deg, rgb(116, 29, 41), rgb(156, 77, 88));
}
.bg-blue{
  background: linear-gradient(0deg, rgb(29, 41, 116), rgb(47, 65, 178));
}
.bg-green{
  background: linear-gradient(0deg, rgb(15, 111, 17), rgb(15, 161, 18));
}
.bg-dark-green{
  background: linear-gradient(0deg, rgb(27, 108, 114), rgb(36, 152, 161));
}
.bg-light-yellow{
  background: linear-gradient(0deg, rgb(157, 143, 18), rgb(223, 204, 34));
}
.grids {
  padding: 10px;
  border-radius: 100%;
  display: flex;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
}
label {
  display: block;
  margin-top: 10px;
}

#wrapper {
  background: #f5eaea;
  box-sizing: border-box;
  padding: 2rem 1rem;
  margin: auto;
  height: 100%;
}

.hidden {
  display: none!important;
}

/* Buttons */
.btn {
  background: purple;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.btn.btn-large {
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
}

.btn:hover {
  color: #fff;
}

.btn img.loading {
  width: 25px;
  margin-right: 1rem;
  margin-left: -1rem;
}

@media (min-width: 1200px) {
  #wrapper {
    max-width: 1140px;
  }
}

.wrapperForGrid {
  display: grid;
  grid-template-columns: 2fr 2fr;
}
.wrapperForGrid2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.card-container.card {
  max-width: 350px;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.App {
  text-align: center;
}

h1 {
  color: rgb(255, 166, 0);
}

#Calendar {
  height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  /* background: yellow; */
}

.rbc-month-view {
  flex-basis: 500px !important;
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: 16px;
  color: rgb(37, 66, 197)
}

.rbc-date-cell {
  color: rgb(15, 114, 226);
}

.rbc-today {
  background-color: rgb(196, 226, 250) !important;
}
.MuiImageListItemBar-title{
  font-size: 11px!important;
}
#blocks button.MuiButtonBase-root{
  background-color: #fff!important;
  padding: 20px 10px!important;
  border-radius: 0.75rem!important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  transition: 0.5s all;
  overflow: hidden;
}
#blocks button.MuiButtonBase-root:hover{
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 20%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  transition: 0.5s all;
}
.message-box .card{
  padding: 13px 10px 8px 10px!important;
  margin: 20px 0px!important;
}
.message-box .card:first-child{
  padding: 8px!important;
  margin: 0px!important;
}
.message-box .card p{
  padding: 0!important;
  margin: 0px!important;
}
@media (max-width: 480px) {
  .rbc-toolbar {
    display: block !important;
  }
}
.rbc-toolbar {
  font-size: 12px!important;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}
.rbc-header {
  font-size: 10px!important;
}
.rbc-time-view {
  background: #fff;
  border-radius: 10px;
}
.rbc-label{
  font-size: 16px;
}
.rbc-day-slot .rbc-event-label{
  font-size: 10px;
}
.rbc-day-slot .rbc-event-content{
  font-size: 10px;
  font-weight: 600; 
}
.calendar-popup .MuiFormControl-root {
  margin-bottom: 20px!important;
  width: 100%;
}
.MuiFormControl-root{
  margin: 0 10px 10px 0!important;
}
.MuiMenuItem-root{
  font-size: 12px!important;
}
.MuiSelect-select.MuiSelect-select {
  font-size: 11px!important;
}
.calendar-popup label + .MuiInput-formControl {
  margin-top: 20px!important;
}
.calendar-popup .MuiInputLabel-formControl, .calendar-popup .MuiInputBase-input {
  font-size: 16px;
}
.calendar-popup .MuiTypography-h6 {
  font-size: 16px;
}
.titleBar .MuiButton-label{
  font-size: 8px;
}
.titleBar h3 {
  font-size: 15px!important;
}
table .MuiButton-label{
  font-size: 14px;
}
#matilda1 {
  width: 50%;
}
#L {
  
  stroke: #000;
  stroke-width: 2;
  stroke-dasharray: 50;
  animation: L 2s infinite ease;
  -webkit-animation: L infinite 2s ease;
}

@keyframes dash {
  100% {
    stroke-dashoffset: -50;
  }
}

@keyframes L {
  100% {
    stroke-dashoffset: 100;
  }
} 

.content-area h4{
    font-size: 18px;
    margin: 0 0 10px 0;
}
.content-area input.form-control{
  margin: 0 0 20px 0;
  padding: 0 15px;
  height: 40px!important;
  border-radius: 5px!important;
  font-size: 16px;
}
.content-area #connect-btn{
  font-size: 12px!important;
    padding: 5px 20px!important;
}
#call-controls {
margin-bottom: 10px;
}
#call-controls .btn{
    font-size: 16px;
    padding: 0 24px;
    height: 30px;
} 
.MuiInputBase-inputMarginDense {
  padding-top: 7px!important;
}
.MuiFormHelperText-root {
  font-size: 9px;
}
.MuiInputLabel-formControl{
  font-size: 13px;
}
.accountTable thead tr th{
  padding: 8px 16px!important;
  background: #d9e6fa;
  font-size: 10px;
  font-weight: bold;
}
.accountTable tbody tr th{
  padding: 8px 16px!important;
  font-size: 10px;
}
.accountTable tbody tr td{
  padding: 8px 16px!important;
  font-size: 10px;
}
.accountTable tbody th button {
  padding: 3px 0;
  border: 1px solid red;
  background: red;
  color: #fff;
}
.accountTable .MuiButton-label {
  font-size: 8px;
}
.MuiDialogTitle-root {
  padding: 10px 25px;
    background: #e3e3e3;
}
.MuiDialogTitle-root h2 {
  font-size: 13px;
}
.MuiInputLabel-formControl {
  font-size: 13px!important;
}
.MuiButton-label {
  font-size: 10px;
}
.MuiTypography-body1 {
  font-size: 12px!important;
}
.goog-te-gadget-simple .goog-te-menu-value span {
  font-size: 10px!important;
}
.goog-te-gadget-simple {
  border: 1px solid #fff!important;
  font-size: 13px!important;
  padding: 0px!important;
  border-radius: 4px!important;
}
.goog-te-menu-frame body .text{
  font-size: 8px!important;
}
.makeStyles-image-19{
  margin: 10px!important;
    background: #fff!important;
    padding: 5px!important;
}
.makeStyles-imageTitle-24 {
  padding: 0!important;
  font-size: 15px!important;
}
.main-content img{
  width: 100%;
  height: 100%;
}
.custom-legend {
  display: flex;
  flex-direction: column;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  width: 20px;
  height: 8px;
  margin-right: 5px;
}