.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #6b929e;
    width: 120px;
    height: 120px;
    -webkit-animation: loader-spin 2s linear infinite;
    animation: loader-spin 2s linear infinite;
}

@-webkit-keyframes loader-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}