#matilda1 {
  display: block;
  margin: auto;
  width: 200px;
  height: 250px;
  transform: scaleX(-1);
}
.titleBar{
  margin-bottom: 10px!important;
}
#call-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ccc;
  padding: 20px;
  border-radius: 10px;
  height: calc(100vh - 60px);
  position: relative;
    overflow: hidden;
}

.form-control {
  margin: 2rem 0;
  font-size: 1.5rem;
}

#connection-status {
  font-size: 16px;
  color: #fff;
  position: absolute;
  background: rgb(0 0 0 / 30%);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#controls-container h4 {
  margin-bottom: 2rem;
  text-align: center;
}

#video-container {
  position: relative;
  height: 513px;
  width: 100%;
}

#controls-container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

#photo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: none;
}

#box-1 {
  -webkit-border-radius: 1px;
  -moz-border-radius: 10px;
  -border-radius: 10px;
}

.form-rounded {
  border-radius: 1rem;
}

#local-video {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin: 16px;
  border: 2px solid #fff;
}

#remote-video {
  max-width: 100%;
  height: 100%;
  background: #000;
}

#call-controls {
  display: flex;
  justify-content: space-between;
}

#call-controls.hidden {
  display: none;
}

#end-call {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px;
  background-color: red;
  color: white;
  border: none;
  margin: 16px;
}

.the-center-div {
  margin: 0 auto;
  width: 60%
}

/* The below code is for Arrow Experiance  */

html {
  background: radial-gradient(#000, #111);
  height: 100%;
}

.keys {
  text-align: center;
  width: 200px;
  margin: 1rem auto;
}

.arr {
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 25px;
  background: #87808a;
  border-radius: 3px;
  color: white;
  font-size: 25px;
  border-right: 5px solid darken(#87808a, 20);
  border-bottom: 5px solid darken(#87808a, 20);
  border-left: 5px solid darken(#87808a, 15);
  border-top: 5px solid darken(#87808a, 10);
  display: inline-block;
  margin: 5px;
  transition: all .05s linear;

  &:active {
    border-bottom: 8px solid darken(#87808a, 20);
    text-shadow: 0 0 10px white, 0 0 10px white, 0 0 20px white;
    transform: translate(0, 2px);
  }

  user-select: none;
}

.up {
  position: relative;
  top: -4px;
}

.pressed {
  border-bottom: 8px solid darken(#87808a, 20);
  text-shadow: 0 0 10px white;
  transform: translate(0, 2px);
}

.names {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  text-align: center;
  color: white;
  text-shadow: 0 0 10px white, 0 0 10px white, 0 0 10px white, 0 0 10px white;
}

.b {
  color: blue;
  text-shadow: 0 0 10px blue, 0 0 10px blue, 0 0 10px blue, 0 0 10px blue;
  font-size: 100px;
  font-family: serif;
}

.a {
  color: yellow;
  text-shadow: 0 0 10px yellow, 0 0 10px yellow, 0 0 10px yellow, 0 0 10px yellow;
  font-size: 100px;
  font-family: serif;
}

@media (min-width: 768px) {
  #call-box {
    grid-template-columns: 1fr 1fr;
  }
}